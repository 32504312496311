.uplot, .uplot *, .uplot *::before, .uplot *::after {box-sizing: border-box;}.uplot {font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";line-height: 1.5;width: max-content;}.uplot .title {text-align: center;font-size: 18px;font-weight: bold;}.uplot .wrap {position: relative;user-select: none;}.uplot .over, .uplot .under {position: absolute;overflow: hidden;}.uplot canvas {display: block;position: relative;width: 100%;height: 100%;}.uplot .legend {font-size: 14px;margin: auto;text-align: center;}.uplot .legend.inline {display: block;}.uplot .legend.inline * {display: inline-block;}.uplot .legend.inline tr {margin-right: 16px;}.uplot .legend th {font-weight: 600;}.uplot .legend th > * {vertical-align: middle;display: inline-block;}.uplot .legend .ident {width: 1em;height: 1em;margin-right: 4px;border: 2px solid transparent;}.uplot .legend.inline th::after {content: ":";vertical-align: middle;}.uplot .legend .series > * {padding: 4px;}.uplot .legend .series th {cursor: pointer;}.uplot .legend .off > * {opacity: 0.3;}.uplot .select {background: rgba(0,0,0,0.07);position: absolute;pointer-events: none;}.uplot .select.off {display: none;}.uplot .cursor-x, .uplot .cursor-y {position: absolute;left: 0;top: 0;pointer-events: none;will-change: transform;z-index: 100;}.uplot .cursor-x {height: 100%;border-right: 1px dashed #607D8B;}.uplot .cursor-y {width: 100%;border-bottom: 1px dashed #607D8B;}.uplot .cursor-pt {position: absolute;top: 0;left: 0;border-radius: 50%;filter: brightness(85%);pointer-events: none;will-change: transform;z-index: 100;}
@font-face {
  font-family: 'icomoon';
  src:
    url('icomoon.26d3d291.ttf') format('truetype'),
    url('icomoon.a1429cb9.woff') format('woff'),
    url('icomoon.9fc0dd81.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star:before {
  content: "\f005";
}

.icon-star-o:before {
  content: "\f006";
}

.icon-lock:before {
  content: "\f023";
}

.icon-eye:before {
  content: "\f06e";
}

.icon-eye-slash:before {
  content: "\f070";
}

.icon-cogs:before {
  content: "\f085";
}

.icon-gears:before {
  content: "\f085";
}

.icon-globe:before {
  content: "\f0ac";
}

.icon-code:before {
  content: "\f121";
}

.icon-sort-amount-desc:before {
  content: "\f161";
}

.icon-street-view:before {
  content: "\f21d";
}

* {
    margin: 0;
    padding: 0;
}

:root {
  --color-dark-gray: #A3A3A3;
  --color-gold: #FFD700;
  --color-dark-purple: #6c5ce7;
  --color-light-purple: #a29bfe;
  --color-dark-blue: #0984e3;
  --color-light-blue: #74b9ff;

  --theme-color-dark: #3B3738;
  --theme-color-light: #EBEBEB;

  --border-radius: 1px;

  /* Light theme */
  --color-purple: var(--color-light-purple);
  --color-blue: var(--color-light-blue);
  --color-blue-inverted: var(--color-dark-blue);
  --background-color: var(--theme-color-light);
  --text-decoration-color: var(--theme-color-dark);
  --text-color: #000;
  --text-color-inverted: #FFF;
}

body {
  background: #212021;
  color: #FFF;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-purple: var(--color-dark-purple);
    --color-blue: var(--color-dark-blue);
    --color-blue-inverted: var(--color-light-blue);
    --background-color: var(--theme-color-dark);
    --text-decoration-color: var(--theme-color-light);
    --text-color: #FFF;
    --text-color-inverted: #000;
  }

  body {
    background: #1c1b1c;
    color: #FFF;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  min-width: 800px;
}

/* Page layout */

html, body {
    height: 100%;
}

a {
  text-decoration: none;
  color: var(--color-blue);
}

a:hover {
  color: var(--background-color);
  cursor: pointer;
}

#push {
  display: none;
  position: relative;
  min-height: 100%;
}

strong {
    font-weight: 700;
}

/* Logo */

.logo-text {
  letter-spacing: -3px;
}

/* Header */

header {
  overflow: auto;
  padding: 20px;
}

header .column-left {
  float: left;
}

header .column-right {
  float: right;
}

header .logo-image {
  --fixed-logo-image-size: 36px;
  width: var(--fixed-logo-image-size);
  height: var(--fixed-logo-image-size);
  margin-right: 5px;
  display: inline-block;
}

header .logo-text {
  font-size: 48px;
  margin: -6px 0;
  display: inline-block;
}

header .logo-status {
  font-size: 21px;
}

header .header-button {
  color: var(--text-color);
  width: 83px;
  height: 83px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  margin-left: -5px;
}

header .header-button > span:first-of-type {
  display: block;
  margin-top: 10px;
  font-size: 22px;
}

header .header-button-single {
  display: none;
  cursor: pointer;
  border-radius: var(--border-radius);
}

header .header-button-single:hover {
  background: var(--background-color) !important;
}

/* Footer */

footer {
  display: none;
  background: var(--background-color);
  color: var(--text-color);
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  padding: 10px 0;
  text-align: center;
  width: 90%;
  margin: 15px auto 0 auto;
}

footer a {
  color: var(--color-blue-inverted);
}

footer a:hover {
  color: var(--text-color);
}

/* Status overly */

#status-overlay {
  padding: 20px 0;
  background: var(--background-color);
  color: var(--text-color);
  border-radius: var(--border-radius);
  text-align: center;
  height: 150px;
  width: 350px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -95px;
  margin-left: -175px;
}

#status-overlay .logo-image {
  --fixed-logo-image-size: 72px;
  width: var(--fixed-logo-image-size);
  height: var(--fixed-logo-image-size);
}

/* Floating tooltip */

#tooltip {
  display: none;
  position: absolute;
  padding: 5px 8px;
  border-radius: var(--border-radius);
  background: var(--background-color);
  color: var(--text-color);
  z-index: 10000;
}

/* Server listing */

#server-list {
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.server {
  padding: 5px 10px;
  margin: 0 5px;
  width: 800px;
  display: inline-block;
}

.server .column {
  float: left;
}

.server .column-favicon {
  width: 80px;
}

.server .column-favicon .server-favicon {
  --fixed-server-favicon-size: 64px;
  width: var(--fixed-server-favicon-size);
  height: var(--fixed-server-favicon-size);
  border-radius: var(--border-radius);
  margin-top: 5px;
}

.server .column-favicon .server-rank {
  display: block;
  width: 64px;
  text-align: center;
}

.server .column-status {
  width: 282px;
}

.server .column-status .server-name {
  display: inline-block;
}

.server .column-status .server-error {
  display: none;
  color: #e74c3c;
}

.server .column-status .server-label {
  color: var(--color-dark-gray);
  font-size: 16px;
  display: none;
}

.server .column-status .server-value {
  color: var(--color-dark-gray);
  font-size: 16px;
}

.server .column-graph {
  height: 100px;
  width: 400px;
}

/* Favorite icons */

.server-is-favorite {
  cursor: pointer;
  color: var(--color-gold);
}

.server-is-favorite:hover::before {
  content: "\f006";
}

.server-is-not-favorite {
  cursor: pointer;
  color: var(--background-color);
}

.server-is-not-favorite:hover {
  color: var(--color-gold);
}

/* Highlighted values */

.server-highlighted-label {
  font-size: 18px;
}

.server-highlighted-value {
  font-size: 18px;
  font-weight: 700;
}

/* Global stats */

.global-stat {
  font-weight: 700;
}

/* Sort by */

#sort-by {
  background: var(--color-purple);
}

/* Settings toggle */

#settings-toggle {
  background: var(--color-blue);
}

/* Historical graph */

#big-graph {
  padding-right: 65px;
}

#big-graph, #big-graph-controls, #big-graph-checkboxes {
    width: 90%;
}

#big-graph-checkboxes > table {
    width: 100%;
}

#big-graph {
  margin: 0 auto;
}

#big-graph-controls {
  margin: 10px auto;
  display: none;
}

.graph-controls-show {
  color: var(--text-color);
}

#big-graph-controls .icon-star {
  color: var(--color-gold);
}

#big-graph-controls-drawer {
  background: var(--background-color);
  color: var(--text-color);
  border-radius: var(--border-radius);
  padding-bottom: 10px;
  overflow: auto;
  display: none;
}

#big-graph-controls-drawer .graph-controls-setall {
  text-align: center;
  display: block;
  margin: 15px 0;
}

#big-graph-checkboxes {
  margin: 15px auto 0 auto;
}

/* Basic elements */

.button {
  background: var(--color-blue);
  border-radius: var(--border-radius);
  font-size: 16px;
  padding: 5px 10px;
}

.button:hover {
  background: var(--text-color);
  color: var(--text-color-inverted);
}

/* Percentage bar */

#perc-bar {
  height: 35px;
  position: relative;
  overflow-x: hidden;
}

#perc-bar .perc-bar-part {
  height: 100%;
  display: inline-block;
  position: absolute;
}

/* Header rows */

@media only screen and (max-width: 1050px) {
  header {
    padding: 0 !important;
  }

  .header-possible-row-break {
    padding-top: 20px;
    width: 100%;
    text-align: center;
  }

  .header-possible-row-break:last-of-type {
    margin-bottom: 20px;
  }
}

/* uPlot.css overrides */

.uplot .select {
  background: var(--color-blue);
  opacity: 0.3;
}
/*# sourceMappingURL=index.ce504551.css.map */
